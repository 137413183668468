import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "gatsby";
import MuiLink from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

type Link = {
  url: string;
  name: string;
};
type Props = {
  links: Link[];
};

export default function ActiveLastBreadcrumb(props: Props) {
  const { links } = props;
  return (
    <Breadcrumbs
      style={{ padding: 8 }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {links.map(({ name, url }, key) => (
        <MuiLink color="inherit" key={key} component={Link} to={`${url}`}>
          {name}
        </MuiLink>
      ))}
    </Breadcrumbs>
  );
}

ActiveLastBreadcrumb.defaultProps = {
  links: [],
};
